import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Foot  from '../components/Foot'
import bull from '../assets/images/istockphoto-1158762452-1024x1024.jpg'
import ads from '../assets/images/add_campaing.webp'

const Smm = props => (
  <Layout>
  <Helmet
  htmlAttributes={{ lang: 'en' }}
  title="Social Media Marketing - Bulls i Marketing"
  meta={[
    { name: 'description', content: 'Ad Driven Campaigns and Organic Driven Campaigns' },
    { name: 'keywords', content: 'Bulls i Marketing, Ad Driven Campaign, Organic Driven Campaign,Integrate Accounts, Conversion Tracking, Build Remarketing Campaign,Product Campaign, Awareness Campaigns, Community Engagement ' },
  ]}
></Helmet>

    <div id="main" className="wrapper style2">
      <div className="container">
      <article>
        <header className="major">
          <h2>Social Media Marketing</h2>
          <p>
          The 10 hours retainer consists of both the Organic Focus and Ad Focus sections, or you can choose one of these options for a 5 hours retainer.  
          </p>
          <p>Our primary social media deliverables per client are special and are definitely not limited to the following:</p>
        </header>

   
        <section>
            <h2>Advertisement Driven Campaign Deliverables</h2>
            <section>
                <p>
                <b>Integrating Accounts:</b> It is essential to provide account access through the business manager to everyone requested work and build ownership for our clients. This includes access to any business managers, pages, ad accounts, groups to work expertly without any password. Accounts give us advertiser access usually, so you still have control over your account any past history with that account. This is an easy process and it makes the whole project easier to do for you when you’re a boss having several employees or teams working on your accounts tracking who is posing what and when ads are being shuffled.</p>
            </section>
            <section>
                <p><b>Set Up Pixel and Analytics Conversion Tracking:</b> Pixel and analytics conversion tracking tells us how well the ad campaigns are performing for the investment. We will walk you through adding code on your website to track where the traffic navigates, what they do, if they buy anything, etc. This allows us to see if you are being benefitted and to A/B test accurately. This form of tracking and UTM parameters leads to impressive remarketing campaigns lowering your cost per acquisition.</p>
            </section>
            <section>
                <p><b>Building Remarketing Campaigns (Segmentation for e-commerce):</b> As discussed above, after the pixel and analytics code are deployed, remarketing campaigns can be set up. This permits us to remarket a service or a product to the same individual who navigated through your website but didn’t convert. The target here is to launch campaigns either around the services or products they visited more or feed them with more information about you to get them to a final decision. Important is to keep your brand at the top of their mind and the best thing is you don’t pay unless they click to open it.</p>
            </section>
            <section>
                <p><b>Awareness/Traffic Driving Campaigns:</b> The purpose of awareness campaigns or brand campaigns is to build the image of your coming among people through a social presence. Likes are not the trust factor for a company’s social presence but having the following is surely a huge factor. Informational articles or videos can also serve this purpose.</p>
            </section>
            <section>
                <p><b>Audience Building/Lookalike Campaigns:</b> Audience building takes the most time of any ad campaign being launched and it’s a lot of work. The key to targeting the right person you are interested in marketing to is done best using your buyer persona. With the passage of time using Pixel, an audience will be built on social media platforms like Facebook and Instagram. Those people will be targeted based on common interests that are converting and appear to be a lookalike audience. This provides us the best chance at a perfect customer and enables us to boost our remarketing campaigns.</p>
            </section>
            <section>
                <p><b>Full Ad Creation:</b> Regardless of the platform we are running our campaigns on, ads vary on the basis of social media platforms, we strive to create highly diverse ads, result-driven using text/URLs/images/CTAs, etc. To assure that we are continuously improving the results as any of these pieces can hit the audience and start showing visible outcomes, we A/B test them based on the key performance indicator discussed. *Creating Images is an added cost*</p>
            </section>
            <section>
                <p><b>A/B Testing (KPI):</b> A/B testing helps us to figure out which ad and copy are working, and which one is not using only one variable at a time without making any changes to it. A client is most of the time looking for sales, so their key performance indicator is conversion. It is not always about sales, sometimes it is about awareness or promotion of a particular event for which special types of campaigns are run. Ticket sales are surely the goal, but campaigns are launched pre-release of a movie or a concert are equally important as to spread awareness. Once all the variables are tested in the whole week (5 business days only), we check the back to see if there are any results. If there are no significant results, we retarget the lookalike audience and build everything again from scratch.</p>
            </section>

        <div className="box alt">
            <div className="row gtr-50 gtr-uniform aln-center">
                <div className="off-2-medium col-8-medium col-12-small">
                    <span className="image fit" style={{maxHeight:"420px"}} >
                        <img src={ads} alt="" />
                    </span>
                </div>
            </div>
        </div>

        </section>

        <section>
            <h2>Organic Driven Campaign Deliverables</h2>
            <section>
                <p><b>Development of Brand Voice and Tone:</b> Consistent posts about the same general idea are posted to maintain the brand voice and tone. Keep your posts relevant to your industry and post them during times when you have the most of your audiences active such as posts about sleep disorders or suggestions to help you get to sleep are great if you’re in the health care industry but it won’t help if you’re a glass manufacturer. We uplift your engagement and make sure whoever messages you get an automated reply that one of our professionals will be addressing their concerns or questions soon.</p>
            </section>
            <section>
                <p><b>Content Curation/Repurposing Content:</b> Using your own website’s content in the form of videos/articles will produce more results than pulling images/videos/articles from other web or social media pages. It is very important to post this content on the native platform we are striving in and either mold existing content according to what we need or use what others have already used, giving them credit where it is necessary. Repurposing content is an effective way to build the credibility of your brand voice and may help you turn it into an authority in the industry.</p>
            </section>
            <section>
                <p><b>Posting (Testing day/time):</b> Posting at the right time when your audience is present is of great importance. For example, the average life of a tweet on Twitter is 18 minutes and most retweets are done within 7 minutes. This ensures that users in this space are active on the social platform, see something they are interested in, and then share it as a part of it. Figuring out when to tweet/post/reshare information is essential for the success of a campaign and will be regularly tested for the best results across the entire business industry.</p>
            </section>
            <section>
                <p><b>Community Engagement:</b> When you have established your following and is active, it demands that you are continuously in touch with them. The point where you lose your success is “deliver and disappear.” We don’t just rely on automated replies, but keep your brand humanized by answering follower’s questions, adding value to the conversation that happens on your page’s posts, liking and commenting on your reshares, asking questions to encourage audience’s engagement, and more like it. We will be working to build a generic FAQ (with answers) for your followers in case they have queries that require your skills.</p>
            </section>

        </section>

        </article>
      </div>
    </div>
    <Foot className="style3" title="Improve your Social Media Marketing now!"/>
  </Layout>
)

export default Smm
